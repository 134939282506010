import { Utils } from '../../common';
import { DateFNS } from '../../common/3rd';
import { PredefinedCheckRuleOptions } from '../../types';

export const AgeRange = (options: PredefinedCheckRuleOptions): boolean => {
	let { value, params } = options;
	if (Utils.isEmpty(value) || Utils.isEmpty(params)) {
		// 值为空, 不需要比较, 通过校验
		return true;
	}

	let min = 0,
		max = 999999,
		includeMax = true,
		includeMin = false,
		minInDay = false,
		maxInDay = false;
	// eslint-disable-next-line
	const defs = params.match(/^(\[|\()?(\d{1,3})?([d|D\y|Y]?),(\d{1,3})?([d|D\y|Y]?)(\]|\)?)$/);
	if (defs == null) {
		throw new Error(`Parameters[${params}] is incorrect.`);
	}
	// 默认开区间，[闭区间
	includeMin = defs[1] === '[' ? true : false;
	if (defs[2]) {
		min = defs[2];
	}
	minInDay = defs[3] === 'd' || defs[3] === 'D';
	if (defs[4]) {
		max = defs[4];
	}
	maxInDay = defs[5] === 'y' || defs[5] === 'Y';
	// 右包不写]等价于]，但是)代表开区间
	includeMax = defs[6] === ')' ? false : true;

	let ageInYear = value;
	let ageInDays = 0;
	if (value.indexOf('/') !== -1) {
		// 是日期
		ageInYear = DateFNS.diffInYears(new Date(), value);
		if (minInDay || maxInDay) {
			if (includeMin) {
				// 这里的日期计算器diffInDays对于生日为天的场景计算逻辑错误，由于出生即1天，故需要+1
				ageInDays = DateFNS.diffInDays(new Date(), value) + 1;
			} else {
				ageInDays = DateFNS.diffInDays(new Date(), value);
			}
		}
	}

	if (minInDay) {
		// 最小值按天计算
		if (includeMin) {
			// 包括最小值
			if (ageInDays < min) {
				return false;
			}
		} else if (ageInDays <= min) {
			// 不包括最小值
			return false;
		}
	} else {
		// 最小值按年计算
		if (includeMin) {
			// 包括最小值
			if (ageInYear < min) {
				return false;
			}
		} else if (ageInYear <= min) {
			// 不包括最小值
			return false;
		}
	}

	if (maxInDay) {
		// 最大值按天计算
		if (includeMax) {
			// 包括最大值
			if (ageInDays > max) {
				return false;
			}
		} else if (ageInDays >= max) {
			// 不包括最大值
			return false;
		}
	} else {
		// 最大值按年计算
		if (includeMax) {
			// 包括最大值
			if (ageInYear > max) {
				return false;
			}
		} else if (ageInYear >= max) {
			// 不包括最大值
			return false;
		}
	}

	return true;
};
